<template>
  <validation-provider :rules="rules" v-slot="{ errors }" :name="name" :immediate="false" ref="provider">
    <div class="form-group" :class="{'has-error': errors.length, 'valid': !errors.length}">
      <div class="input-group" :class="{'has-margin-top': label || margin}">
        <label class="control-label" v-if="label">
          {{ label }}
          <a v-if="tooltip" v-b-tooltip.hover :title="tooltip" class="fa fa-question-circle tooltip-icon" />
        </label>
        <vue-tel-input mode="international" @validate="handleValidate" @focus="handleBlur" @input="handleInput" @country-changed="handleCountryChange"  v-model="phoneNumber" :inputId="id" :name="name" :placeholder="placeholder" :maxLen="maxLen" :disabled="disabled" :onlyCountries="countries" :disabledFetchingCountry="disabledFetchingCountry" :class="{'flag-right': flagPosition === 'right'}"></vue-tel-input>
        <!-- <i class="bar"></i> -->
        <small v-show="errors.length" class="help text-danger">{{ errors[0] }}</small>
        <small class="help" v-if="help">{{ help }}</small>
      </div>
    </div>
  </validation-provider>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    tooltip: {
      type: String,
      default: null,
    },
    help: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    allowValidation: {
      type: Boolean,
      default: true
    },
    maxLen: {
      type: Number,
      default: 25
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onlyCountries: {
      type: Array,
      default: undefined
    },
    disabledFetchingCountry: {
      type: Boolean,
      default: true,
    },
    flagPosition: {
      type: String,
      default: 'left',
    },
    required: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      countries: ['US', 'CA'],
      country: '',
      margin: '',
      phoneNumber: '',
      blur: false,
      rules: {
        required: this.required,
        regex: /^.{1,}$/,
      },
      id: '_' + Math.random().toString(36).substr(2, 9)
    }
  },

  mounted () {
    this.phoneNumber = this.value || ''
    this.loadCountries();
  },

  methods: {
    loadCountries() {
      if (this.onlyCountries)
      {
        this.countries = this.onlyCountries
      } else {
        this.countries = this.$store.getters['auth/countries']
      }
    },

    handleValidate (args) {
      var numberValid = false;
      if (this.allowValidation)
      {
        if (args.isValid) {
          this.rules.regex = /^.{6,}$/
          this.$emit('input', args.number.input)
          numberValid = true;
        } else {
          this.rules.regex = /^.{36,}$/
        }
      } else {
        this.rules.required = false
      }
      this.$emit('onNumberValid', numberValid)
    },

    handleInput (number, args) {
      this.country = args.regionCode
      this.$emit('onInputChange', args)
      this.$emit('input', number)
    },

    handleCountryChange (args) {
      this.country = args.iso2
      this.$emit('onCountryChange', this.country)
    },

    handleBlur () {
      this.blur = true
    }
  },

  watch: {
    value: {
      handler (value) {
        this.phoneNumber = value || ''
      },
      immediate: true,
    }
  },
}
</script>

<style lang="scss">
  .form-group .vue-tel-input {
    background: #FFF;
    border: 0.0625rem solid #e7e7e7;
    border-radius: 0px;
    input {
      border: none;
      height: 100%;
      line-height: 100%;
      margin-top: 0;
      padding: 7px 10px;
      &::-webkit-input-placeholder , /* Chrome/Opera/Safari */
      &::-moz-placeholder , /* Firefox 19+ */
      &:-ms-input-placeholder, /* IE 10+ */
      &:-moz-placeholder { /* Firefox 18- */
          line-height: 100%;
          height: 100%;
      }
    }
    + .bar:before {
      z-index: 0;
    }
  }
</style>
<style lang="scss" scoped>
  .form-group .control-label {
    pointer-events: unset;
    overflow: inherit;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .tooltip-icon {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
    margin-top: calc( 4px - 8px);
  }
  .flag-right {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
</style>